<template>
  <div class="settings">
    <v-row>
      <v-col cols="12">
        <v-form
          ref="form"
          class="multi-col-validation"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveSettings()"
        >
          <v-card
            flat
            class="pa-3 mt-2"
          >
            <v-card-text class="pt-5">
              <h3 class="font-weight-medium">
                Update prepaid meters settings
              </h3>
            </v-card-text>
            <v-card-text>
              <v-label>Operation fee</v-label>
              <v-row class="mt-1">
                <v-col cols="3">
                  <v-select
                    v-model="form.prepaid_operation_fee_in"
                    label="Operation fee in"
                    class="md-1"
                    :items="OperationFeeOptions"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    :loading="isLoading"
                    :disabled="isLoading"
                    @change="prepaid_operation_feeOptionChange()"
                  ></v-select>
                  <small
                    v-show="form.errors.has('prepaid_operation_fee_in')"
                    class="validation-error"
                  >{{
                    form.errors.get('prepaid_operation_fee_in')
                  }}</small>
                </v-col>
              </v-row>
              <v-row
                v-for="(operation_fee, index) in form.prepaid_operation_fee"
                :key="index"
                class="mt-1"
              >
                <v-col md="2">
                  <v-text-field
                    v-model="operation_fee.from"
                    outlined
                    dense
                    label="From (Ksh)"
                    placeholder="1"
                    :rules="numberRules"
                    :loading="isLoading"
                    :disabled="isLoading"
                    required
                  />
                </v-col>
                <v-col>
                  <p class="text-center mt-2">
                    TO
                  </p>
                </v-col>
                <v-col md="2">
                  <v-text-field
                    v-model="operation_fee.to"
                    outlined
                    dense
                    label="To (Ksh)"
                    placeholder="100"
                    :rules="numberRules"
                    :loading="isLoading"
                    :disabled="isLoading"
                    required
                  />
                </v-col>
                <v-col>
                  <p
                    class="text-center mt-1"
                    style="font-size: 18px"
                  >
                    =
                  </p>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    v-model="operation_fee.amount"
                    :label="prepaid_operation_feeLabel"
                    placeholder="130"
                    dense
                    outlined
                    :rules="numberRulesAllowZero"
                    :loading="isLoading"
                    :disabled="isLoading"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-btn
                    v-show="index === 0"
                    class="ml-4"
                    small
                    outlined
                    fab
                    color="primary"
                    @click="addPrepaidOperationFeeInput"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-show="index != 0"
                    class="ml-4"
                    small
                    outlined
                    fab
                    color="primary"
                    @click="removePrepaidOperationFeeInput(index)"
                  >
                    <v-icon>{{ icons.mdiClose }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <small
                v-show="form.errors.has('prepaid_operation_fee')"
                class="validation-error"
              >{{
                form.errors.get('prepaid_operation_fee')
              }}</small>
            </v-card-text><br />

            <v-divider></v-divider>

            <v-card-text class="pt-5">
              <h3 class="font-weight-medium">
                Update post-paid meters settings
              </h3>
            </v-card-text>
            <v-card-text>
              <v-label>Operation fee</v-label>
              <v-row class="mt-1">
                <v-col cols="3">
                  <v-select
                    v-model="form.postpaid_operation_fee_in"
                    label="Operation fee in"
                    class="md-1"
                    :items="OperationFeeOptions"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    :loading="isLoading"
                    :disabled="isLoading"
                    @change="postpaidOperationFeeOptionChange()"
                  ></v-select>
                  <small
                    v-show="form.errors.has('postpaid_operation_fee_in')"
                    class="validation-error"
                  >{{
                    form.errors.get('postpaid_operation_fee_in')
                  }}</small>
                </v-col>
              </v-row>
              <v-row
                v-for="(operation_fee, index) in form.postpaid_operation_fee"
                :key="index"
                class="mt-1"
              >
                <v-col md="2">
                  <v-text-field
                    v-model="operation_fee.from"
                    outlined
                    dense
                    label="From (Ksh)"
                    placeholder="1"
                    :rules="numberRules"
                    :loading="isLoading"
                    :disabled="isLoading"
                    required
                  />
                </v-col>
                <v-col>
                  <p class="text-center mt-2">
                    TO
                  </p>
                </v-col>
                <v-col md="2">
                  <v-text-field
                    v-model="operation_fee.to"
                    outlined
                    dense
                    label="To (Ksh)"
                    placeholder="100"
                    :rules="numberRules"
                    :loading="isLoading"
                    :disabled="isLoading"
                    required
                  />
                </v-col>
                <v-col>
                  <p
                    class="text-center mt-1"
                    style="font-size: 18px"
                  >
                    =
                  </p>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    v-model="operation_fee.amount"
                    :label="postpaid_operation_feeLabel"
                    placeholder="130"
                    dense
                    outlined
                    :rules="numberRulesAllowZero"
                    :loading="isLoading"
                    :disabled="isLoading"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-btn
                    v-show="index === 0"
                    class="ml-4"
                    small
                    outlined
                    fab
                    color="primary"
                    @click="addPostpaidOperationFeeInput"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-show="index != 0"
                    class="ml-4"
                    small
                    outlined
                    fab
                    color="primary"
                    @click="removePostpaidOperationFeeInput(index)"
                  >
                    <v-icon>{{ icons.mdiClose }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <small
                v-show="form.errors.has('prepaid_operation_fee')"
                class="validation-error"
              >{{
                form.errors.get('prepaid_operation_fee')
              }}</small>
            </v-card-text>
            <br />
            <v-divider></v-divider>

            <v-card-text>
              <v-row>
                <h3 class="font-weight-medium">
                  Withdraw to
                </h3>
                <v-col md="12">
                  <v-radio-group
                    v-model="form.withdraw_to"
                    :rules="[v => !!v || 'Please select a withdrawal type']"
                    row
                  >
                    <v-radio
                      label="Phone Number"
                      value="phone_number"
                    ></v-radio>
                    <v-radio
                      label="Paybill Number"
                      value="paybill_number"
                    ></v-radio>
                    <v-radio
                      label="Till Number"
                      value="till_number"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="form.withdraw_to_value"
                    :label="withdrawToLabel"
                    type="number"
                    :placeholder="withdrawToPlaceholder"
                    :rules="withdrawToRules"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-text>
              <v-btn
                color="primary"
                class="me-3 mt-3"
                type="submit"
                :loading="form.busy"
              >
                Save changes
              </v-btn>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import { mdiPlus, mdiClose } from '@mdi/js'
import { serialize } from 'object-to-formdata'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  data() {
    return {
      isLoading: true,
      OperationFeeOptions: [
        {
          name: 'Fixed Amount',
          value: 0,
        },
        {
          name: 'Percentage',
          value: 1,
        },
      ],
      icons: {
        mdiPlus,
        mdiClose,
      },
      resellerID: this.$route.params.id,
      prepaid_operation_feeLabel: 'Operation fee (in %)',
      postpaid_operation_feeLabel: 'Operation fee (in Ksh)',
      form: new Form({
        prepaid_operation_fee_in: '',
        prepaid_operation_fee: [
          {
            from: '',
            to: '',
            amount: '',
          },
        ],
        postpaid_operation_fee_in: '',
        postpaid_operation_fee: [
          {
            from: '',
            to: '',
            amount: '',
          },
        ],
        withdraw_to: 'phone_number',
        withdraw_to_value: '',
      }),
    }
  },
  computed: {
    withdrawToLabel() {
      switch (this.form.withdraw_to) {
        case 'phone_number':
          return 'Phone Number'
        case 'paybill_number':
          return 'Paybill Number'
        case 'till_number':
          return 'Till Number'
        default:
          return ''
      }
    },
    withdrawToPlaceholder() {
      switch (this.form.withdraw_to) {
        case 'phone_number':
          return '07xxxxxxxx'
        case 'paybill_number':
          return 'Enter Paybill Number'
        case 'till_number':
          return 'Enter Till Number'
        default:
          return ''
      }
    },
    withdrawToRules() {
      if (this.form.withdraw_to === 'phone_number') {
        return this.phoneRules
      }

      return this.numberRules
    },
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    getSettings() {
      this.isLoading = true
      axios
        .get(`settings/${this.resellerID}`)
        .then(response => {
          response.data.meter_settings.forEach(meterSetting => {
            if (meterSetting.for === 'prepay') {
              this.form.prepaid_operation_fee_in = meterSetting.operation_fee_in_percentage
              this.form.prepaid_operation_fee = meterSetting.operation_charges
            }
            if (meterSetting.for === 'post-pay') {
              this.form.postpaid_operation_fee_in = meterSetting.operation_fee_in_percentage
              this.form.postpaid_operation_fee = meterSetting.operation_charges
            }
          })
          this.postpaidOperationFeeOptionChange()
          this.prepaid_operation_feeOptionChange()
          const { settings } = response.data
          const withdrawTo = settings.find(setting => setting.key === 'withdraw_to')
          const withdrawToValue = settings.find(setting => setting.key === 'withdraw_to_value')

          this.form.withdraw_to = withdrawTo ? withdrawTo.value : null
          this.form.withdraw_to_value = withdrawToValue ? withdrawToValue.value : null
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    saveSettings() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .submit('post', `settings/${this.resellerID}`, {
            // Transform form objects to FormData
            transformRequest: [
              // eslint-disable-next-line no-unused-vars
              function (data, headers) {
                const { prepaid_operation_fee } = data
                const { postpaid_operation_fee } = data
                delete data.prepaid_operation_fee
                delete data.postpaid_operation_fee
                const serializedData = serialize(data)
                serializedData.append('prepaid_operation_fee', JSON.stringify(prepaid_operation_fee))
                serializedData.append('postpaid_operation_fee', JSON.stringify(postpaid_operation_fee))

                return serializedData
              },
            ],
          })
          .then(() => {
            this.$notification.success('Settings updated successfully')
            this.setMeterReadingDate(this.form.meter_reading_on)
          })
          .catch(error => {
            console.log('🚀 ~ file: ResellerDetails.vue ~ line 372 ~ saveSettings ~ error', error)
            this.$notification.error(error.response.data.message)
          })
      }
    },
    postpaidOperationFeeOptionChange() {
      if (this.form.postpaid_operation_fee_in === 0) {
        this.postpaid_operation_feeLabel = 'Operation fee (in Ksh)'
      } else {
        this.postpaid_operation_feeLabel = 'Operation fee (in %)'
      }
    },
    prepaid_operation_feeOptionChange() {
      if (this.form.prepaid_operation_fee_in === 0) {
        this.prepaid_operation_feeLabel = 'Operation fee (in Ksh)'
      } else {
        this.prepaid_operation_feeLabel = 'Operation fee (in %)'
      }
    },
    addPrepaidOperationFeeInput() {
      if (this.form.prepaid_operation_fee.length > 15) {
        this.$notification.error('You can only add a maximum of 15 price range')

        return
      }
      this.form.prepaid_operation_fee.push({ from: '', to: '', amount: '' })
    },
    removePrepaidOperationFeeInput(index) {
      this.form.prepaid_operation_fee.splice(index, 1)
    },
    addPostpaidOperationFeeInput() {
      if (this.form.postpaid_operation_fee.length > 15) {
        this.$notification.error('You can only add a maximum of 5 price range')

        return
      }
      this.form.postpaid_operation_fee.push({ from: '', to: '', amount: '' })
    },
    removePostpaidOperationFeeInput(index) {
      this.form.postpaid_operation_fee.splice(index, 1)
    },
  },

}
</script>
